import(/* webpackMode: "eager" */ "/opt/script/frontend/senegaljoogjob/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/script/frontend/senegaljoogjob/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/opt/script/frontend/senegaljoogjob/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/script/frontend/senegaljoogjob/src/components/footer/footer.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/script/frontend/senegaljoogjob/src/components/navBar/links/Links.js");
;
import(/* webpackMode: "eager" */ "/opt/script/frontend/senegaljoogjob/src/components/navBar/navBar.module.css");
;
import(/* webpackMode: "eager" */ "/opt/script/frontend/senegaljoogjob/src/app/layout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/script/frontend/senegaljoogjob/src/providers/Providers.js");
